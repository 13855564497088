import React from "react";
import logo from "../logo512.png";
import downloadios from "../assets/download_ios.png";
import downloadandroid from "../assets/download_play.png";
import "../App.css";

function Home() {
  return (
    <div className="App">
      <div className="background-gradient">
        <div className="stars"></div>
        <header className="App-header">
          <div className="content-wrapper">
            <div className="hero-section">
              <div className="logo-container">
                <div className="glow-effect"></div>
                <img src={logo} className="App-logo floating" alt="logo" />
              </div>

              <h1 className="title">
                <span className="gradient-text">Award Party</span>
              </h1>

              <div className="description">
                <p className="tagline">
                  ✨ The Ultimate Awards Show Experience
                </p>
                <p className="subtitle">
                  Join your friends, predict winners, and compete for the top
                  spot on the leaderboard. Make every awards show an
                  unforgettable party!
                </p>
              </div>
            </div>

            <div className="download-section">
              <div className="download-card ios">
                <a
                  className="download-link"
                  href="https://apps.apple.com/us/app/award-party/id6476841113"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={downloadios}
                    className="download-button"
                    alt="Download for iOS"
                  />
                </a>
              </div>

              <div className="download-card android">
                <div className="coming-soon-wrapper">
                  <img
                    src={downloadandroid}
                    className="download-button disabled"
                    alt="Android Version Coming Soon"
                  />
                  <div className="coming-soon-badge">
                    <span className="badge-text">Coming Soon</span>
                    <div className="badge-glow"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="features">
              <div className="feature-card">
                <div className="feature-icon">🎯</div>
                <h3>Make Your Predictions</h3>
                <p>Guess the winners and earn points</p>
              </div>
              <div className="feature-card">
                <div className="feature-icon">🏆</div>
                <h3>Compete with Friends</h3>
                <p>Rise to the top of the leaderboard</p>
              </div>
              <div className="feature-card">
                <div className="feature-icon">🎉</div>
                <h3>Celebrate Together</h3>
                <p>Make every show memorable</p>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
}

export default Home;
